import React, { useRef, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { breakpoints, flex, colors } from "@styles/vars";
import { useAuth } from "@contexts/AuthProvider";
import Sidebar from "@layout/Sidebar/index";
import usePageIsOverflow from "@hooks/usePageIsOverflow";
import UploadPopUp from "@widgets/Dox/UploadPopUp";
import botIcon from "@assets/bot_siri.png";
import { ChatWindow } from "@widgets/Dox/ChatBot/ChatWindow";
import Menubar from "Dashboard/Menubar";
import NewPanel from "@layout/Panel/NewPanel";
import BotIcon from "@fonts/menu_icons/botIcon";
import { useInterfaceContext } from "@contexts/interfaceContext";
import Progress from "@ui/Progress";
import ScrollProgress from "@ui/ScrollProgress";
import uploadIconGif from "../src/assets/upload animation.gif";
import theme from "styled-theming";

const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${theme("theme", {
    light: "black",
    dark: "white",
  })};
`;

const ChatBotWrapper = styled.div`
  height: 60px;
  width: 60px;
  z-index: 6;
  position: fixed;
  bottom: 540px;
  right: 375px;

  ${breakpoints.mobileM} {
    z-index: 9999;
    bottom: 475px;
    right: 300px;
  }
  ${breakpoints.tablet} {
    bottom: 505px;
    right: 350px;
  }
`;

const ChatBot = styled.div`
  height: 70px;
  width: 70px;
  z-index: 2;
  cursor: pointer;
  position: fixed;
  bottom: 25px;
  right: 60px;

  &:hover {
    transform: scale(0.95);
  }

  img {
    position: relative;
    z-index: 2;
  }

  ${breakpoints.mobileM} {
    bottom: 40px;
    right: 10px;
  }
  ${breakpoints.tablet} {
    bottom: 40px;
    right: 60px;
  }
`;

const UploadIconWrapper = styled.div`
  position: fixed;
  bottom: 155px;
  right: 69px;
  z-index: 3;
  cursor: pointer;
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: calc(100% - 20px);
  max-width: 600px;
  background: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 205px;
  right: 20px;
  z-index: 10;
  color: black;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  opacity: 1;
  backdrop-filter: blur(21px);
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
`;

const DocUploadWrapper = styled.div`
  ${flex.row};
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 600px;
  height: auto;
  z-index: 999;
  // background: linear-gradient(125.49deg, #bea9d0 1.93%, #d9cae4 43.61%, #fef7ff 96.53%);
  background-color: white;
  border: 1px solid #bea9d0;
  border-radius: 10px;
  padding: 10px;
  white-space: nowrap;
  margin-bottom: 10px;

  p {
    margin: 0;
    color: black;
    font-size: 0.775rem;
    white-space: nowrap;
  }

  ${breakpoints.tablet} {
    padding: 5px;
    top: 100px;

    p {
      font-size: 0.58rem;
    }
  }

  ${breakpoints.laptop} {
    max-width: calc(100% - 20px);
    padding: 10px;
    top: 75px;
    p {
      font-size: 0.775rem;
    }
  }

  ${breakpoints.laptopL} {
    max-width: 600px;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${flex.center};
  width: 80%;
  gap: 10px;
`;

export const Modal = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const handleUploadClick = () => {
    setIsUploadModalOpen(!isUploadModalOpen);
  };

  const { isUploading, uploadCount, totalUploadCount, documentType } =
    useInterfaceContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isUploadModalOpen && !event.target.closest(".modal-content")) {
        setIsUploadModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isUploadModalOpen]);

  const handleCloseModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <>
      {isUploading && (
        <UploadIconWrapper onClick={handleUploadClick}>
          <img
            src={uploadIconGif}
            alt="Upload"
            style={{ width: 40, height: 40 }}
          />
        </UploadIconWrapper>
      )}
      {isUploadModalOpen && isUploading && (
        <ModalWrapper className="modal-content">
          <CloseIcon onClick={handleCloseModal}>X</CloseIcon>
          <h2 style={{ color: "white" }}>Upload Files</h2>
          <br />

          {Object.keys(totalUploadCount).map((key, index) => (
            <DocUploadWrapper key={index}>
              <p>{documentType} Documents Uploading</p>
              <ProgressWrapper style={{ width: "50%" }}>
                <Progress
                  value={Math.round(
                    (uploadCount[key] / totalUploadCount[key]) * 100
                  )}
                  color={colors.progress_bg}
                  style={{ width: "80%" }}
                />
                {uploadCount[key]} of {totalUploadCount[key]}
              </ProgressWrapper>
            </DocUploadWrapper>
          ))}
          <br />
        </ModalWrapper>
      )}
    </>
  );
};

const AppLayout = () => {
  const auth = useAuth();
  const [isShowBot, setIsShowBot] = useState(false);
  const appRef = useRef(null);
  const isOverflow = usePageIsOverflow();

  const handleClick = () => {
    setIsShowBot(!isShowBot);
  };

  useEffect(() => {
    auth.getUserInfo();
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    // <div className="bg_layout">
    <div className="app" ref={appRef}>
      {isOverflow ? <ScrollProgress /> : null}
      <UploadPopUp />
      {/* <Menubar /> */}
      <Sidebar />
      <div className="app_content">
        {auth.error ? (
          <h4>{auth.error}</h4>
        ) : (
          <>
            <NewPanel />
            <Outlet />
            <ChatBotWrapper>
              {isShowBot && <ChatWindow />}
              <ChatBot onClick={handleClick}>
                <img src={botIcon} alt="chatbot" />
              </ChatBot>
            </ChatBotWrapper>
            <Modal />
          </>
        )}
      </div>
    </div>
    // </div>
  );
};

export default AppLayout;
